<template>
  <div id="tournaments" class="tournaments_container">
    <div class="container">
      <img alt="illu-2" class="bgDot15" src="@/assets/images/play/section3-illu-2.png">
      <img alt="illu-3" class="section3-illu-3" src="@/assets/images/play/section3-illu-3.png">
      <img alt="illu-1" class="section3-illu-1" src="@/assets/images/play/section3-illu-1.png">

      <div class="col-md-10 offset-md-1 mb-50 pt-50">
        <div id="tournament_title" class="">
          <h2 class="mb-30">Tournaments</h2>
        </div>
      </div>
    </div>
    <div class="container tournaments_columns">
      <div class="row">
        <div class="col-md-6">
          <div id="upcoming">Upcoming</div>
          <div
            v-for="(tournament, index) in tournaments.upcoming_tournaments"
            :key="index"
            class="tournament_box"
          >
            <div class="tournament_box_img">
              <img :alt="tournament.title" :src="tournament.image_url">
            </div>
            <div class="tournament_box-content">
              <label>{{ formatDate(tournament.start_date) }}</label>
              <div class="tournament_box_txt">
                <p>{{ tournament.title }}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div id="upcoming">Past</div>
          <div
            v-for="(tournament, index) in tournaments.past_tournaments"
            :key="index"
            class="tournament_box"
          >
            <div class="tournament_box_img">
              <img :alt="tournament.title" :src="tournament.image_url">
            </div>
            <div class="tournament_box-content">
              <label>{{ formatDate(tournament.start_date) }}</label>
              <div class="tournament_box_txt">
                <p>{{ tournament.title }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone'

export default {
  name: 'TournamentsContent',
  data() {
    return {
      tournaments: {},
    };
  },
  methods: {
    formatDate(dateString) {
      const date = new Date(dateString);
      return moment(date).format('MMM DD, YYYY hh:mm A')
    },
  },
  mounted() {
    fetch('https://zensports.com/api/tournaments')
    .then((response) => response.json())
    .then((data) => {
      this.tournaments = data;
    })
    .catch(() => {
      this.tournaments = {
        "upcoming_tournaments":[
        ],
        "past_tournaments":[
          {
            "title": "July Road to Grandmaster Chess Tournament",
            "start_date": "2022-07-23T14:00:00.000-04:00",
            "end_date": null,
            "description": null,
            "image_url": "https://zensports.com/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBEZz09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--758b02d29c4ab097566333d90785ed23a084446a/Untitled%20design-Max-Quality%20-%202022-06-20T114226.835.jpg"
          },
          {
            "title": "ZenSports x Project Ace Splinterlands Invitational",
            "start_date": "2022-07-09T08:00:00.000-04:00",
            "end_date": null,
            "description": null,
            "image_url": "https://zensports.com/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBEUT09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--3b7021503858b2f66aa25d7b2debd45beb3d055f/Untitled%20design-Max-Quality%20-%202022-06-14T124844.251.jpg"
          },
          {
            "title": "ZenSports Axie Infinity Pro Invitational",
            "start_date": "2022-07-02T08:00:00.000-04:00",
            "end_date": null,
            "description": null,
            "image_url": "https://zensports.com/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBEQT09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--c4fa99d4adc6503a06aecb268544a0e89bf6f4a5/Untitled%20design-Max-Quality%20-%202022-06-09T144936.671.jpg"
          },
          {
            "title": "June Chess Road to Grandmaster Chess Tournament",
            "start_date": "2022-06-18T14:00:00.000-04:00",
            "end_date": null,
            "description": null,
            "image_url": "https://zensports.com/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBDZz09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--466f531a76256441443ad75196385e84b91adbd2/Untitled%20design-Max-Quality%20-%202022-05-23T110117.666.jpg"
          },
        ],
      }
    });
  }
}
</script>
