<template>
  <div>

    <header>
      <nav>
        <div class="nav-brand">
          <a href="/">
            <img alt="Zen Sports logo" class="profile_image site-logo" src="@/assets/images/icons/UG-Color.png">
          </a>
        </div>
        <div class="nav-toggler" id="hamburger" @click="openMenu()">
          <img alt="hamburger icon" class="toggler-btn" src="@/assets/images/icons/hamburger-menu.svg">
        </div>
        <div class="nav-container">
          <div class="nav-item">
            <a href="/#product" class="nav-link">Product</a>
          </div>
          <div class="nav-item">
            <a href="/#tournaments" class="nav-link">Tournaments</a>
          </div>
          <div class="nav-item">
            <a href="/#partnergames" class="nav-link">Partner Games</a>
          </div>
        </div>
      </nav>
      <menu type="toolbar">
        <div class="menu-container">
          <div class="company-list">
            <p class="menu-title">Company</p>
            <ul class="menu-list">
              <li class="menu-item">
                <a target="_blank" class="menu-link" href="https://drive.google.com/drive/folders/1QdPiGA0nC5sv-Dk7kysL-abKe0gfsnBI?usp=sharing">Media Kit</a>
              </li>
              <li class="menu-item">
                <a target="_blank" class="menu-link" href="https://zensports.com/podcastnetwork">Podcasts</a>
              </li>
              <li class="menu-item">
                <a target="_blank" class="menu-link" href="https://ultimategamer.com/blog">Blog</a>
              </li>
              <li class="menu-item">
                <a target="_blank" class="menu-link" href="https://keystarcorp.com/">KeyStar Corp</a>
              </li>
              <li class="menu-item">
                <a target="_blank" class="menu-link" href="https://zensports.com/">ZenSports</a>
              </li>
              <li class="menu-item">
                <a target="_blank" class="menu-link" href="https://ultimategamer.com/">Ultimate Gamer</a>
              </li>
              <li class="menu-item">
                <a target="_blank" class="menu-link" href="https://burstive.com/">Burstive</a>
              </li>
            </ul>
          </div>
          <div class="support-list">
            <p class="menu-title">Support</p>
            <ul class="menu-list">
              <li class="menu-item">
                <a target="_blank" class="menu-link" href="https://support.zensports.com/en/">Help Center</a>
              </li>
              <li class="menu-item">
                <a target="_blank" class="menu-link" href="https://support.zensports.com/en/articles/3143483-faq-s">FAQ's</a>
              </li>
              <li class="menu-item">
                <a target="_blank" class="menu-link" href="https://zensports.com/tos">Terms &amp; Conditions</a>
              </li>
              <li class="menu-item">
                <a target="_blank" class="menu-link" href="https://zensports.com/privacy">Privacy Policy</a>
              </li>
              <li class="menu-item">
                <a class="menu-link" href="https://zensports.com/responsible-gaming">Responsible Gaming</a>
              </li>
            </ul>
          </div>
          <div class="download-app-list">
            <p class="menu-title">Download App</p>
            <ul class="menu-list">
              <li class="menu-item">
                <a class="menu-link" href="https://apps.apple.com/app/zensports/id1600332635" target="_blank">
                  <img alt="Apple Store" class="" src="@/assets/images/icons/download-store-1@2x.svg">
                </a>
              </li>
              <li class="menu-item">
                <a class="menu-link" href="https://play.google.com/store/apps/details?id=com.zensports.play" target="_blank">
                  <img alt="Play Store" class="" src="@/assets/images/icons/Android.svg">
                </a>
              </li>
            </ul>
          </div>
        </div>
      </menu>

      <div class="hero-bg-color-tile-1">
        <img class="hero-bg-tile-1" alt="hero bg tile 1" src="@/assets/images/play/hero-rillu-right@2x.png">
      </div>
      <div class="hero-bg-color-tile-3">
        <img class="hero-bg-tile-3 lazyloaded" alt="hero bg tile 3" src="@/assets/images/play/hero-left-illu@2x.png">
      </div>
      <div class="hero-content">
        <div class="hero-tile">
          <img class="hero-bg-tile-2 lazyloaded" alt="hero bg tile 2" src="@/assets/images/play/svg/hero-title@3x.svg">
        </div>
        <div class="app-platforms-container">
          <a href="https://apps.apple.com/app/zensports/id1600332635" target="_blank" rel="noopener noreferrer">
            <img alt="Apple store" class="" src="@/assets/images/play/svg/hero-apple@3x.svg">
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.zensports.play" target="_blank" rel="noopener noreferrer">
            <img alt="Play store" class="" src="@/assets/images/play/svg/hero-android@3x.svg">
          </a>
        </div>
        <div class="hero-title">Play ESPORTS TODAY!</div>
        <div class="hero-sub-title">Ultimate Gamer allows gamers to create, play in, and watch esports tournaments. Tournaments occur weekly, and are always FREE to play in. Most tournaments offer real prize money for the winners.</div>
      </div>
    </header>

    <section class="services">
      <div class="container-fluid container-dragon">
        <div class="hero-illu">
          <img class="hero-bottom-illu lazyloaded" alt="hero-bottom-illu" src="@/assets/images/play/hero-bottom-illu.png">
        </div>
        <div id="product" class="col-lx-10 col-md-10 offset-lx-1 next_level_text_container">
          <div class="row mt-40">
            <div class="col-md-4 d-flex align-items-stretch">
              <img alt="section3-title-with-bg" class="" src="@/assets/images/play/section3-title-with-bg.png">
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="container-fluid play_features">
      <div class="play_container">
        <div class="row">
          <div class="col-md-4">
            <img alt="registration_payment" class="" src="@/assets/images/play/registration_payment.png">
          </div>
          <div class="col-md-4">
            <img alt="r_b" class="" src="@/assets/images/play/r_b.png">
          </div>
          <div class="col-md-4">
            <img alt="loyalty and rewards" class="" src="@/assets/images/play/loyalty_rewards.png">
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <img alt="play_profile" class="" src="@/assets/images/play/play_profile.png">
          </div>
          <div class="col-md-4">
            <img alt="broad_casting" class="" src="@/assets/images/play/broad_casting.png">
          </div>
          <div class="col-md-4">
            <img alt="price_money" class="" src="@/assets/images/play/price_money.png">
          </div>
        </div>
      </div>
    </div>

    <Tournaments />

    <div id="partnergames" class="container-fluid partner_games">
      <div class="row">
        <div class="col-md-12 partner_game_heading">
          <div class="partner_game_title">
            <h2 class="mb-30">Partner Games</h2>
          </div>
          <div class="partner_game_sub_heading">
            <p>
              Ultimate Gamer has partnered with some of the top game titles in the esports industry to host tournaments for their games:
            </p>
          </div>
        </div>
        <div class="col-md-12 partner_game_container mt-30">
          <div class="row">
            <div class="col-md-6">
              <div class="partner_game-card card-1">
                <img alt="axie infinity" class="img-desktop" src="@/assets/images/play/section4-card-1.png">
                <img alt="axie infinity" class="img-mobile" src="@/assets/images/play/section4-card-1-mobile@2x.png">
                <div class="partner_game-card-container">
                  <div class="partner_game-card-content">
                    <div class="card-title">
                      Axie Infinity
                    </div>
                    <div class="card-sub-title">
                      Axie Infinity is a Pokémon-inspired universe where anyone can earn tokens through skilled gameplay and contributions to the ecosystem.
                    </div>
                  </div>
                  <div class="partner_game-card-content-hover">
                    <div class="card-title">
                      Axie Infinity
                    </div>
                    <div class="card-sub-title">
                      Axie Infinity is a Pokémon-inspired universe where anyone can earn tokens through skilled gameplay and contributions to the ecosystem. Players can battle, collect, raise, and build a land-based kingdom for their pets. All art assets and Axie genetic data can be easily accessed by 3rd parties, allowing community developers to build their own tools and experiences in the Axie Infinity universe. Although Axie is still in early access, Axie is ranked the #1 Ethereum game by daily, weekly, and monthly active users. While Axie is a fun game, it's also taken on characteristics of a social network and jobs platform due to the strong community and play to earn opportunities that have come from its early success.
                    </div>
                  </div>
                </div>
              </div>
              <div class="partner_game-card card-2">
                <img alt="lichess" class="img-desktop" src="@/assets/images/play/section4-card-2.png">
                <img alt="lichess" class="img-mobile" src="@/assets/images/play/section4-card-2-mobile@2x.png">
                <div class="partner_game-card-container">
                  <div class="partner_game-card-content">
                    <div class="card-title">
                      Lichess
                    </div>
                    <div class="card-sub-title">
                      Lichess is a free/libre, open-source chess server powered by volunteers and donations.
                    </div>
                  </div>
                  <div class="partner_game-card-content-hover">
                    <div class="card-title">
                      Lichess
                    </div>
                    <div class="card-sub-title">
                      Lichess is a free/libre, open-source chess server powered by volunteers and donations. Today, Lichess users play more than five million games every day. Lichess is one of the most popular chess websites in the world while remaining 100% free. Most "free" websites subsist by selling ads or selling user data. Others do it by putting all the good stuff behind paywalls. Lichess does none of these things and never will. With no investors demanding profits, Lichess staff can focus on imporving the site as their only goal.
                    </div>
                  </div>
                </div>
              </div>
            </div>
    
            <div class="col-md-6">
              <div class="partner_game-card card-4">
                <img alt="splinterlands" class="img-desktop" src="@/assets/images/play/section4-card-4.png">
                <img alt="splinterlands" class="img-mobile" src="@/assets/images/play/section4-card-4-mobile@2x.png">
                <div class="partner_game-card-container">
                  <div class="partner_game-card-content">
                    <div class="card-title">
                      Splinterlands
                    </div>
                    <div class="card-sub-title">
                      Splinterlands is a leading blockchain-based trading card game
                    </div>
                  </div>
                  <div class="partner_game-card-content-hover">
                    <div class="card-title">
                      Splinterlands
                    </div>
                    <div class="card-sub-title">
                      Splinterlands is a leading blockchain-based trading card game that is powered by the most advanced, robust, and innovative Play To Earn ecosystem in the world. Players are empowered to own their in-game assets. Trade on numerous marketplaces across multiple blockchains. Play on the mobile app straight from your desktop's internet browser. Earn by winning battles, competing in leagues, or participating in tournaments. Splinterlands represents the future of gaming. We are growing repidly, and we look forward to taking to the battlefield with you!
                    </div>
                  </div>
                </div>
              </div>
    
              <div class="partner_game-card card-5">
                <img alt="placewar" class="img-desktop" src="@/assets/images/play/section4-card-5.png">
                <img alt="placewar" class="img-mobile" src="@/assets/images/play/section4-card-5-mobile@2x.png">
                <div class="partner_game-card-container">
                  <div class="partner_game-card-content">
                    <div class="card-title">
                      Placewar
                    </div>
                    <div class="card-sub-title">
                        Gamified Internet Culture Metaverse, the battle to earn (P2E) social Gamefi strategic artillery game ecosystem.
                    </div>
                  </div>
                  <div class="partner_game-card-content-hover">
                    <div class="card-title">
                      Placewar
                    </div>
                    <div class="card-sub-title">
                        Gamified Internet Culture Metaverse, the battle to earn (P2E) social Gamefi strategic artillery game ecosystem. Multi-chain with highly engaging artillery gameplay that allows players to create and define the outlook of the in-game world through building, battles, and alliances. Inspired by Worm and r/place, PlaceWar creates a gamified and open Internet culture metaverse and puts users in control. You are guaranteed a gaming experience that you've never seen before in any other Play-To-Earn games. We integrate battle and creation, and we make sure there is an incentive mechanism to support them both. Create, by conquest. Earn, with strategies. Gear up, commander!
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
    
          <div class="row">
            <div class="col-md-6">
              <div class="partner_game-card card-3">
                <img alt="registration_payment" class="img-desktop" src="@/assets/images/play/section4-card-3.png">
                <img alt="registration_payment" class="img-mobile" src="@/assets/images/play/section4-card-3-mobile@2x.png">
                <div class="partner_game-card-container">
                  <div class="partner_game-card-content">
                    <div class="card-title">
                      Satoshis Games Inc
                    </div>
                    <div class="card-sub-title">
                        Satoshis Games SL develops video games that integrate the Internet of Money (Bitcoin) to solve three main problems that affect gamers' experience: unsecure currency, lack of economic incentives, and interoperability.
                    </div>
                  </div>
                  <div class="partner_game-card-content-hover">
                    <div class="card-title">
                      Satoshis Games Inc
                    </div>
                    <div class="card-sub-title">
                      Satoshis Games SL develops video games that integrate the Internet of Money (Bitcoin) to solve three main problems that affect gamers' experience: unsecure currency, lack of economic incentives, and interoperability.
                    </div>
                  </div>
                </div>
              </div>
            </div>
    
            <div class="col-md-6">
              <div class="partner_game-card card-6">
                <img alt="registration_payment" class="img-desktop" src="@/assets/images/play/section4-card-6.png">
                <img alt="registration_payment" class="img-mobile" src="@/assets/images/play/section4-card-6-mobile@2x.png">
                <div class="partner_game-card-container">
                  <div class="partner_game-card-content">
                    <div class="card-title">
                      Xonotic
                    </div>
                    <div class="card-sub-title">
                      Xonotic is a free and open-source first-person shooter video game.
                    </div>
                  </div>
                  <div class="partner_game-card-content-hover">
                    <div class="card-title">
                      Xonotic
                    </div>
                    <div class="card-sub-title">
                      Xonotic is a free and open-source first-person shooter video game. There are sixteen different game modes in Xonotic, including classic modes like deathmatch and capture the flag. In order to unlock more game modes, players must complete different levels of gameplay. To score points, players must kill enemies using futuristic weapons while completing objectives.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <footer id="footer">
      <div class="footer-top">
        <div class="container">
          <div class="row">
            <div class="footerHrLine"></div>
            <div class="col-lg-4 col-md-6 footer-links">
              <h4 class="underLine">COMPANY</h4>
              <ul>
                <li>
                  <a target="_blank" class="" href="https://drive.google.com/drive/folders/1QdPiGA0nC5sv-Dk7kysL-abKe0gfsnBI?usp=sharing">Media Kit</a>
                </li>
                <li>
                  <a class="" href="https://zensports.com/podcastnetwork">Podcasts</a>
                </li>
                <li>
                  <a target="_blank" class="" href="https://ultimategamer.com/blog">Blog</a>
                </li>
                <li>
                  <a target="_blank" class="" href="https://keystarcorp.com/">KeyStar Corp</a>
                </li>
                <li>
                  <a target="_blank" class="" href="https://zensports.com/">ZenSports</a>
                </li>
                <li>
                  <a target="_blank" class="" href="https://ultimategamer.com/">Ultimate Gamer</a>
                </li>
                <li>
                  <a target="_blank" class="" href="https://burstive.com/">Burstive</a>
                </li>
              </ul>
            </div>
            <div class="col-lg-4 col-md-6 footer-links">
              <h4 class="underLine">SUPPORT</h4>
              <ul>
                <li>
                  <a target="_blank" class="" href="https://support.zensports.com/en/">Help Center</a>
                </li>
                <li>
                  <a target="_blank" class="" href="https://support.zensports.com/en/articles/3143483-faq-s">FAQ's</a>
                </li>
                <li>
                  <a target="_blank" class="" href="https://zensports.com/tos">Terms &amp; Conditions</a>
                </li>
                <li>
                  <a target="_blank" class="" href="https://zensports.com/privacy">Privacy Policy</a>
                </li>
                <li>
                  <a class="" href="https://zensports.com/responsible-gaming">Responsible Gaming</a>
                </li>
              </ul>
            </div>
            <div class="col-lg-4 col-md-6 footer-links">
              <h4 class="underLine">MOBILE APP</h4>
              <ul>
                <li>
                  <a href="https://apps.apple.com/app/zensports/id1600332635" target="_blank" rel="noopener noreferrer">
                    <img alt="Apple store" class="" src="@/assets/images/icons/download-store-1@2x.svg">
                  </a></li>
                <li>
                  <a href="https://play.google.com/store/apps/details?id=com.zensports.play" target="_blank" rel="noopener noreferrer">
                    <img alt="Play store" class="" src="@/assets/images/icons/Android.svg">
                  </a>
                </li>
              </ul>
              <div class="social-icons-container">
                <a href="https://www.facebook.com/TheUltGmr" target="_blank">
                  <img alt="facebook" class="" src="@/assets/images/icons/FB_icon@2x.svg">
                </a>
                <a href="https://twitter.com/TheUltGmr" target="_blank">
                  <img alt="twitter" class="" src="@/assets/images/icons/TW_icon@2x.svg">
                </a>
                <a href="https://www.linkedin.com/company/ultimategamer/about/" target="_blank">
                  <img alt="linkedin" class="" src="@/assets/images/icons/LI_icon@2x.svg">
                </a>
                <a href="https://www.youtube.com/channel/UC6URPq4gp_dO3ejlbTSoBJQ" target="_blank">
                  <img alt="youtube" class="" src="@/assets/images/icons/Youtube_Color.svg">
                </a>
                <a href="https://www.twitch.tv/theultgmr" target="_blank">
                  <img alt="twitch" class="" src="@/assets/images/icons/icon-twitch.svg">
                </a>
                <a href="https://www.instagram.com/the.ultimate.gamer/" target="_blank">
                  <img alt="instagram" class="" src="@/assets/images/icons/icon-instagram.svg">
                </a>
                <a href="https://discord.me/ultimategamer" target="_blank">
                  <img alt="discord" class="" src="@/assets/images/icons/icon-discord.svg">
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>

  </div>
</template>

<script>
import Tournaments from '../components/Tournaments.vue'

export default {
  name: 'LandingPage',
  components: {
    Tournaments
  },
  methods: {
    openMenu() {
      document.getElementsByTagName("menu")[0].classList.toggle("active-menu");
    }
  },
  mounted() {
    function hideMenu() {
      document.getElementsByTagName("menu")[0].classList.remove("active-menu");
    }
    document.addEventListener("click", (event) => {
      if (event.target.className !== "toggler-btn") {
        hideMenu();
      }
    });
  }
}
</script>
